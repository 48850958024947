import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import loadable from '@loadable/component';

import { Container, Row, Column } from 'common/components/styleguide/grid';
import { List } from 'common/components/styleguide/List';

import {
  ICON_LOGO_FACEBOOK,
  ICON_LOGO_INSTAGRAM,
  ICON_LOGO_GOOGLE,
  ICON_LOGO_YOUTUBE,
  ICON_MAIL,
  ICON_PHONE,
} from 'common/constants/IconConstants';

const RouteLink = loadable(() => import('common/components/_base/RouteLink'));
const TextIcon = loadable(() =>
  import('common/components/styleguide/TextIcon')
);
const LanguageSelector = loadable(
  () => import('common/blocks/functional/languageSelector'),
  { ssr: false }
);

import { withCssUnit } from 'common/components/styleguide/utils';

const DEFAULT_TOPBAR_HEIGHT = '35px';

const S = {};

S.TobBar = styled.section`
  display: none;
  font-size: 0.75em;
  background: ${({ theme }) => _.get(theme, 'topBar.background', null)};
  border-bottom: ${({ theme }) => _.get(theme, 'topBar.borderBottom', null)};
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    display: block;
  }
  ${({ theme }) => css`
    ${_.get(theme, 'topBar.customStyle', '')}}
  `}
`;

S.Nav = styled.nav`
  height: ${({ theme }) => _.get(theme, 'topBar.height', null)};
  display: flex;
  align-items: center;
`;

S.TopBarInfo = styled.section`
  height: ${({ theme }) =>
    _.get(theme, 'topBar.height', DEFAULT_TOPBAR_HEIGHT)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.TopBarAcions = styled.section`
  height: ${({ theme }) =>
    _.get(theme, 'topBar.height', DEFAULT_TOPBAR_HEIGHT)};
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const SocialLink = ({ socialObject, icon, type }) => {
  if (!_.get(socialObject, 'active', false)) {
    return null;
  }
  return (
    <li>
      <RouteLink
        rel={'nofollow'}
        href={_.get(socialObject, 'link')}
        linkType={type}
        margin={'0 5px'}
      >
        <TextIcon iconClassName={icon}>{_.get(socialObject, 'name')}</TextIcon>
      </RouteLink>
    </li>
  );
};

const TopBarVariant1 = ({
  data,
  topbarSettings,
  topbarMenu,
}) => {
  return (
    <S.TobBar>
      <Container>
        <Row>
          <Column
            size={{ xs: 12, md: 3 }}
            visible={{ xs: false, sm: false, md: true }}
          >
            {_.get(topbarSettings, 'isMenuEnabled', true) &&
            !_.isEmpty(topbarMenu) ? (
              <S.Nav>
                <List alignListItems="center" reset inline>
                  {_.map(topbarMenu, (page) => {
                    return (
                      <li key={'top-bar-page-item-' + page.id}>
                        <RouteLink
                          route="static"
                          params={{ urlTitle: page.urlTitle }}
                          linkType="text"
                          margin={'0 5px'}
                        >
                          {page.title}
                        </RouteLink>
                      </li>
                    );
                  })}
                </List>
              </S.Nav>
            ) : null}
          </Column>
          <Column size={{ xs: 12, md: 6 }}>
            <S.TopBarInfo>
              <List alignListItems="center" reset inline>
                {_.get(topbarSettings, 'isPhoneEnabled', true) && data.phone ? (
                  <li>
                    <RouteLink
                      href={data.phone ? 'tel:' + data.phone : ''}
                      linkType="text"
                      margin={'0 5px'}
                    >
                      <TextIcon iconClassName={ICON_PHONE}>
                        {data.phone}
                      </TextIcon>
                    </RouteLink>
                  </li>
                ) : null}
                {_.get(topbarSettings, 'isEmailEnabled', true) && data.email ? (
                  <li>
                    <RouteLink
                      href={'mailto:' + data.email}
                      linkType="text"
                      margin={'0 5px'}
                    >
                      <TextIcon iconClassName={ICON_MAIL}>
                        {data.email}
                      </TextIcon>
                    </RouteLink>
                  </li>
                ) : null}
                <SocialLink
                  socialObject={_.get(data, 'facebook')}
                  icon={ICON_LOGO_FACEBOOK}
                  type={'facebook'}
                />
                <SocialLink
                  socialObject={_.get(data, 'instagram')}
                  icon={ICON_LOGO_INSTAGRAM}
                  type={'instagram'}
                />
                <SocialLink
                  socialObject={_.get(data, 'google')}
                  icon={ICON_LOGO_GOOGLE}
                  type={'google'}
                />
                <SocialLink
                  socialObject={_.get(data, 'youtube')}
                  icon={ICON_LOGO_YOUTUBE}
                  type={'youtube'}
                />
              </List>
            </S.TopBarInfo>
          </Column>
          <Column
            size={{ xs: 12, md: 3 }}
            visible={{ xs: false, sm: false, md: true }}
          >
            <S.TopBarAcions>
              <List alignListItems="center" reset inline>
                <li>
                  <LanguageSelector />
                </li>
              </List>
            </S.TopBarAcions>
          </Column>
        </Row>
      </Container>
    </S.TobBar>
  );
};

export default React.memo(TopBarVariant1);
