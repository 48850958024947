import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { withCssUnit } from 'common/components/styleguide/utils/index';

const StyledList = styled.ul`
  margin: ${props => (props.reset ? 0 : null)};
  padding-left: ${props => (props.reset ? 0 : null)};
  list-style: ${props => (props.reset ? 'none' : null)};
  display: ${props => (props.inline ? 'flex' : 'block')};
  flex-direction: ${props => (props.inline ? 'row' : null)};
  li {
    display: ${props => (props.alignListItems ? 'flex' : null)};
    align-items: ${props => (props.alignListItems ? props.alignListItems : null)};
  }
`;

const List = ({
  children,
  reset = false,
  inline = false,
  alignListItems = '',
  ...rest
}) => {
  return (
    <StyledList
      reset={reset}
      inline={inline}
      alignListItems={alignListItems}
      {...rest}
    >
      {children}
    </StyledList>
  );
};

const StyledListItem = styled.li`
  box-sizing: border-box;
  display: ${props => (props.alignListItems ? 'flex' : null)};
  align-items: ${props => (props.alignListItems ? props.alignListItems : null)};
  color: ${({ theme, color }) => _.get(theme, ['colors', color], 'inherit')};
  background-color: ${({ theme, backgroundColor }) =>
    _.get(theme, ['colors', backgroundColor], 'transparent')};
  line-height: 1.55em;
  padding: ${({ theme, padding, paddingConfig }) => {
    return padding
      ? padding
      : ({ theme, paddingConfig }) => {
          let finalPadding = '';
          const defaultPadding = withCssUnit(_.get(theme, 'gutterWidth', 0));
          finalPadding = _.get(paddingConfig, 'top', false)
            ? defaultPadding + ' '
            : '0 ';
          finalPadding =
            finalPadding +
            (_.get(paddingConfig, 'right', false)
              ? defaultPadding + ' '
              : '0 ');
          finalPadding =
            finalPadding +
            (_.get(paddingConfig, 'bottom', false)
              ? defaultPadding + ' '
              : '0 ');
          finalPadding =
            finalPadding +
            (_.get(paddingConfig, 'left', false) ? defaultPadding + ' ' : '0 ');
          return finalPadding;
        };
  }};
  margin: ${({ theme, margin, marginConfig }) => {
    return margin
      ? margin
      : ({ theme, marginConfig }) => {
          let finalMargin = '';
          const defaultMargin = withCssUnit(_.get(theme, 'gutterWidth', 0));
          finalMargin = _.get(marginConfig, 'top', false)
            ? defaultMargin + ' '
            : '0 ';
          finalMargin =
            finalMargin +
            (_.get(marginConfig, 'right', false) ? defaultMargin + ' ' : '0 ');
          finalMargin =
            finalMargin +
            (_.get(marginConfig, 'bottom', false) ? defaultMargin + ' ' : '0 ');
          finalMargin =
            finalMargin +
            (_.get(marginConfig, 'left', false) ? defaultMargin + ' ' : '0 ');
          return finalMargin;
        };
  }};

  ${({ theme, border, borderConfig }) => {
    return border
      ? `border: ${border};`
      : ({ theme, borderConfig }) => {
          let finalBorder = '';
          const defaultBorderValue =
            _.get(theme, 'border.default.width') +
            ' ' +
            _.get(theme, 'border.default.style') +
            ' ' +
            _.get(theme, 'border.default.color');
          finalBorder = _.get(borderConfig, 'top', false)
            ? 'border-top: ' + defaultBorderValue + '; '
            : '';
          finalBorder =
            finalBorder +
            (_.get(borderConfig, 'right', false)
              ? 'border-right: ' + defaultBorderValue + '; '
              : '');
          finalBorder =
            finalBorder +
            (_.get(borderConfig, 'bottom', false)
              ? 'border-bottom: ' + defaultBorderValue + '; '
              : '');
          finalBorder =
            finalBorder +
            (_.get(borderConfig, 'left', false)
              ? 'border-left: ' + defaultBorderValue + '; '
              : '');
          return finalBorder;
        };
  }}

  ${({ customStyle }) => css`
    ${customStyle}
  `}
`;

const ListItem = ({
  children,
  color,
  backgroundColor,
  inline = false,
  alignListItems = '',
  padding = false,
  margin = false,
  border = false,
  paddingConfig = { top: false, left: false, right: false, bottom: false },
  marginConfig = { top: false, left: false, right: false, bottom: false },
  borderConfig = {},
  customStyle,
  ...rest
}) => {
  return (
    <StyledListItem
      color={color}
      backgroundColor={backgroundColor}
      inline={inline}
      alignListItems={alignListItems}
      padding={padding}
      margin={margin}
      border={border}
      paddingConfig={paddingConfig}
      marginConfig={marginConfig}
      borderConfig={borderConfig}
      customStyle={customStyle}
      {...rest}
    >
      {children}
    </StyledListItem>
  );
};

export default List;
export { List, ListItem };
